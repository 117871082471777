import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { RETAIL_BACKEND } from '@/constants';

const onRequest = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

function setup() {
  axios.defaults.baseURL = RETAIL_BACKEND;

  axios.interceptors.request.use(onRequest, onRequestError);
  axios.interceptors.response.use(onResponse, onResponseError);

  return axios;
}

export default setup();
