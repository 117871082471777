'use client';
import { Children, TouchEvent, useEffect, useRef, useState } from 'react';
import useRefWithSetter from '@/hook/use-ref-setter';
import { ArrowRightDark } from '../../../../public/Images';
import Image from 'next/image';

const CarouselV2 = ({
    children,
    showDots,
    showArrows = false,
    autoPlay = false,
    onNextClick,
    onPrevClick,
    frameDuration = 5000,
}: {
    children: any;
    showDots: boolean;
    showArrows?: boolean;
    autoPlay: boolean;
    onNextClick?: (imageIndex: number) => void;
    onPrevClick?: (imageIndex: number) => void;
    frameDuration: number;
}) => {
    const indexDots = {
        borderRadius: '26px',
        width: '8px',
        height: '8px',
        backgroundColor: 'white',
        marginRight: '6px',
        transition: '0.3s ease-in-out',
        opacity: 0.5,
    };
    const indexDotsActive = {
        borderRadius: '26px',
        width: '16px',
        height: '8px',
        backgroundColor: 'white',
        marginRight: '6px',
        transition: '0.3s ease-in-out',
        opacity: 1,
    };

    const childrenArray = Children.toArray(children) as JSX.Element[];

    const imgRef = useRef<HTMLDivElement>(null);
    const sliderContainer = useRef<HTMLDivElement>(null);
    const [imgsState, setImgsState] = useState<boolean[]>(
        new Array(childrenArray.length)
    );
    const [currentImage, setCurrentImage] = useRefWithSetter(0);

    const xDown = useRef<number | null>(null);
    const yDown = useRef<number | null>(null);
    //TouchEvent |ObjectEvent

    function handleTouchStart(e: TouchEvent) {
        const firstTouch = e.touches[0];
        xDown.current = firstTouch.clientX;
        yDown.current = firstTouch.clientY;
    }

    function handleTouchMove(evt: any, index: number) {
        if (!xDown.current || !yDown.current) {
            return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = xDown.current - xUp;
        const yDiff = yDown.current - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                swip(index, 'left');
            } else {
                swip(index, 'right');
            }
        }
        /* reset values */
        xDown.current = null;
        yDown.current = null;
    }

    const slide = (index: number) => {
        const newImgState = [false];
        if (imgRef.current) {
            imgRef.current.style.transition = '0.4s ease-in-out';
            imgRef.current.style.transform = `translate(calc(-100% * ${index}),0)`;
            for (let i = 0; i < imgsState.length; i++) {
                newImgState[i] = i === index;
            }
        }
        setCurrentImage(index);
        setImgsState(newImgState);
    };
    const swip = (index: number, direction: string) => {
        const newIndex = direction === 'left' ? index + 1 : index - 1;
        let validIndex = newIndex >= imgsState.length ? 0 : newIndex;
        validIndex = validIndex < 0 ? imgsState.length - 1 : validIndex;
        const newImgState = [false];

        if (imgRef.current) {
            imgRef.current.style.transition = '0.4s ease-in-out';
            imgRef.current.style.transform = `translate(calc(-100%  * ${validIndex}),0)`;

            for (let i = 0; i < imgsState.length; i += 1) {
                newImgState[i] = i === validIndex;
            }
        }
        setCurrentImage(validIndex);
        setImgsState(newImgState);
    };
    const [hover, setHover] = useState(false);
    const handleMouseHover = () => {
        setHover(true);
    };
    useEffect(() => {
        let slidingEffect = null;
        if (autoPlay && hover) return;
        if (!autoPlay && !hover) return;
        slidingEffect = setInterval(() => {
            swip(currentImage, 'left');
        }, frameDuration);
        return () => {
            clearInterval(slidingEffect);
        };
    }, [hover]);

    return (
        <div
            ref={sliderContainer}
            className={'w-full overflow-hidden relative h-full'}
            style={{ direction: 'ltr' }}
        >
            <div className={'shimmer'}></div>
            <div
                ref={imgRef}
                className={'h-full  relative whitespace-nowrap text-center'}
            >
                {childrenArray.map((child, index) => (
                    <div
                        className={'inline-block w-full h-full'}
                        key={`${index}-12`}
                        onMouseEnter={() => handleMouseHover()}
                        onMouseLeave={() => setHover(false)}
                        onTouchStart={e => handleTouchStart(e)}
                        onTouchMove={e => handleTouchMove(e, index)}
                        onDragStart={e => e.preventDefault()}
                    >
                        {child}
                    </div>
                ))}
            </div>
            {showDots && (
                <div
                    className={
                        'absolute -translate-x-2/4 translate-y-0 flex left-2/4 bottom-[5%]'
                    }
                >
                    {Array.from({ length: childrenArray.length }).map(
                        (_, index) => (
                            <div
                                onClick={() => slide(index)}
                                key={`indexDotsContainer${index}`}
                                style={
                                    imgsState[index] || currentImage === index
                                        ? indexDotsActive
                                        : indexDots
                                }
                            ></div>
                        )
                    )}
                </div>
            )}
            {showArrows && childrenArray.length > 1 && (
                <>
                    <button
                        className={
                            'absolute w-7 h-7 border flex justify-center items-center p-1 rounded-full bg-white z-10 left-5 sm:px-2 px-1.5 py-0.5 top-[40%]'
                        }
                        onClick={() => {
                            swip(currentImage, 'left');
                            if (onPrevClick) {
                                onPrevClick(currentImage);
                            }
                        }}
                    >
                        <Image
                            className={'-scale-x-100'}
                            src={ArrowRightDark.src}
                            width={20}
                            height={20}
                            alt={'right'}
                        />
                    </button>
                    <button
                        className={
                            'absolute w-7 h-7 border flex justify-center items-center p-1 rounded-full bg-white z-10 right-5 sm:px-2 px-1.5 py-0.5 top-[40%]'
                        }
                        onClick={() => {
                            swip(currentImage, 'right');
                            if (onNextClick) {
                                onNextClick(currentImage);
                            }
                        }}
                    >
                        <Image
                            src={ArrowRightDark.src}
                            width={20}
                            height={20}
                            alt={'right'}
                        />
                    </button>
                </>
            )}
        </div>
    );
};

export default CarouselV2;
