import { UUID_V4_REGEX } from '@/constants';
import pino from 'pino';
import * as Sentry from '@sentry/nextjs';

const logger = pino({ level: 'trace' }).child({
    s: 'src/utils/uuidV4.util.ts',
});

export const isValidUUid = (s: string) => {
    let isValid = !!s.match(UUID_V4_REGEX);
    if (!isValid) {
        let fnLogger = logger.child({ fn: 'isValidUUid' });
        fnLogger.info('failed to validate some uuid');
        // eslint-disable-next-line no-console
        console.trace('INVALID_UUID_trace');
        // eslint-disable-next-line no-console
        fnLogger.info(`provided value is ${JSON.stringify(s)}`);
        Sentry.captureException(new Error('INVALID_UUID'));
    }
    return !!s.match(UUID_V4_REGEX);
};
