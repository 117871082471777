/**
 * @param interestRate interest rate per year
 * @param numberOfMonths number of periods (months)
 * @param loanAmount the value of the loan
 */
export const PMT = (interestRate: number, numberOfMonths: number, loanAmount: number) => {
  const interestRatePerMonth = interestRate / 12;

  let pmt = 0;
  let pvif = 0;

  if (interestRatePerMonth === 0) {
    return loanAmount / numberOfMonths;
  }

  pvif = Math.pow(1 + interestRatePerMonth, numberOfMonths);
  pmt = (interestRatePerMonth * (loanAmount * pvif)) / (pvif - 1);

  return Math.ceil(pmt);
};
export const originalCarPrice = (
  pmt: number,
  interestRate: number,
  numberOfMonths: number,
  downPayment: number,
) => {
  const interestRatePerMonth = interestRate / 12;
  let pvif = 0;

  if (interestRatePerMonth === 0) {
    return downPayment + pmt * numberOfMonths;
  }

  pvif = Math.pow(1 + interestRatePerMonth, numberOfMonths);
  const originalPrice = (pmt * (pvif - 1)) / (interestRatePerMonth * pvif) + downPayment;

  return Math.ceil(originalPrice);
};
