const urlSafeBase64 = string => {
    return new Buffer(string)
        .toString('base64')
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
};

//CONFIGS

const enableNextStaticRegeneration = false;
const skipOptimizationTypes = ['svg', 'gif'];
const enableDebuging = false;

/**
 * Check if next static resource
 * @param src {string}
 * @returns {boolean}
 */
const isNextStaticAsset = src => {
    return !!src.match(/_next\/static/g);
};
/**
 * check if the image is already optimized .
 * @param src
 * @returns {boolean}
 */
const isAlreadyOptimizedImage = (src = '') => {
    return !!src.match(/\.webp$/g);
};

const isNotPublicAvailableImage = src => {
    return !!src.match(/^https.*/g);
};
/**
 * check if optimization should be skipped.
 * @param src {string}
 * @returns {boolean}
 */
const shouldSkipOptimization = src => {
    let result = false;
    const alreadyOptimized = isAlreadyOptimizedImage(src);

    if (alreadyOptimized) {
        return true;
    }
    const isNotPublicAvailable = isNotPublicAvailableImage(src);
    if (!isNotPublicAvailable) {
        return true;
    }

    if (enableNextStaticRegeneration) {
        return !isNextStaticAsset(src);
    }
    result = shouldSkipOptimizationBaseOnType(src);
    return result;
};

/**
 * check optimization types
 * @param src {string}
 * @returns {boolean}
 */
const shouldSkipOptimizationBaseOnType = src => {
    let result = false;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let q = 0; q < skipOptimizationTypes.length; q++) {
        const ext = skipOptimizationTypes[q];
        /* eslint-disable */
        const extReg = new RegExp(`\.${ext}`, 'g');
        if (extReg.test(src)) {
            result = true;
            break;
        }
    }
    return result;
};
/**
 * Build img proxy size param
 * @param width {number}
 * @param height {number}
 * @returns {string}
 */
const buildSizeParam = (width, height) => {
    const baseSizeParam = '/size:';
    let sizeParams = baseSizeParam;
    if (width) {
        sizeParams += `${width}`;
    }
    if (height) {
        sizeParams += `:${height}`;
    }
    if (sizeParams === baseSizeParam) {
        sizeParams = '';
    }
    return sizeParams;
};
/**
 *
 * build imgproxt url .
 * @param src {string}
 * @param width {number}
 * @param quality {number}
 * @param height {number}
 * @param fillColor {string}
 * @returns {string}
 */
const buildOptimizedImagePath = (src, width, quality, height, fillColor) => {
    const safeBase64DecodedUrl = urlSafeBase64(src);
    if (enableDebuging) {
        // eslint-disable-next-line no-console
        console.log('safeBase64DecodedUrl', safeBase64DecodedUrl);
    }
    const sizeParams = buildSizeParam(width, height);
    if (enableDebuging) {
        // eslint-disable-next-line no-console
        console.log('sizeParams', sizeParams);
    }
    let _fillColor = '';
    if (fillColor !== 'transparent') {
        _fillColor = `/background:${fillColor}`;
    }

    const finalUrl = `https://sylndr.com/sig${sizeParams}${_fillColor}/resizing_type:fit/quality:${
        quality && quality > 50 ? quality : 50
    }/${safeBase64DecodedUrl}.webp`;
    if (enableDebuging) {
        // eslint-disable-next-line no-console
        console.log('finalUrl', finalUrl);
    }
    return finalUrl;
};
export default function myImageLoader(data) {
    const { src, width, quality, height, fillColor = '255:255:255' } = data;
    if (!src) {
        return '';
    }

    const shouldSkip = shouldSkipOptimization(src);

    if (shouldSkip) {
        return src;
    }

    return buildOptimizedImagePath(src, width, quality, height, fillColor);
}
