import { useRef } from 'react';

const useRefWithSetter = <T>(val: T | null): [T, (arg: T | null) => void] => {
    const ref = useRef<T>(val || null);
    const setRef = (n: T | null) => {
        ref.current = n;
    };
    return [ref.current, setRef];
};
export default useRefWithSetter;
